import React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section } from '../components/Section'
import BgImg from '../images/brand-list.jpg'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import BreakpointDown from "../components/Media/BreakpointDown"

const SectionAfterBg = styled(Section)`
min-height:100vh;
display:flex;
align-items:center;
.container{
  position:relative;
  z-index:2;
}
 &:after{
   width:100%;
   background-size:cover;
 }
`
const NotFound = styled.div`
  max-width:400px;
  margin-left:auto;
  margin-right:auto;
  position: relative;
  text-align:center;
  ${BreakpointDown.md`
     max-width:320px;
    `}
`
const Content = styled.div`
    margin-top: -72px;
    padding-left:15px;
    padding-right:15px;
    ${BreakpointDown.lg`
     margin-top: -68px;
    `}
     ${BreakpointDown.md`
     margin-top: -56px;
    `}
  a{color:#529A00; font-weight:600}
  p{ margin-top:30px;}
`

const NotFoundPage = () => (
  <Layout pageName="404">
    <SEO title="Page not Found" />
    <SectionAfterBg bgAfter={`url(${BgImg})`} pt="200px" pb="200px" xpt="150px" xpb="150px">
      <div className="container">
        <NotFound>
          <StaticImage
            src="../svg/404.svg"
            alt="Page Not Found"
            placeholder="blurred"
          />
          <Content>
            <h1 className="h2">Page not Found</h1>
            <p>Sorry, we can’t find the page you are looking for <Link to="/"> Click here</Link> to go back to the home page</p>
          </Content>
        </NotFound>
      </div>
    </SectionAfterBg>
  </Layout>
)

export default NotFoundPage
